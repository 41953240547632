.restrict-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  max-width: 940px;
  margin-top: 120px;
  .title {
    color: var(--Text, #162f3f);
    text-align: center;
    font-family: "Google Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-top: 24px;
  }
  .description {
    color: var(--Text2, #667085);
    text-align: center;
    font-family: "Google Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.084px;
    margin-top: 8px;
  }
  .btn {
    padding: 8px 0;
    margin-top: 16px;
    text-align: center;
    font-family: "Google Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}
