.sidebar {
  padding: 16px;
  width: 170px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #f0f0f0;
  .select-item {
    color: var(--Text2, #667085);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    border-radius: 39px;
    padding: 8px 0px 8px 16px;
    margin-bottom: 4px;
    cursor: pointer;
    display: flex;
  }
  .select-item:hover {
    background-color: rgba(208, 213, 221, 0.2);
    color: var(--Text, #162f3f) !important;
  }
  .select-item-active {
    background-color: rgba(208, 213, 221, 0.2) !important;
    color: var(--Text, #162f3f) !important;
    font-weight: 500;
  }
}

.footer {
  display: flex;
  justify-content: end;
  margin: 12px 24px 24px 24px;
  .footer-btn-container {
    display: flex;
    gap: 8px;
  }
}
