.input-phone {
  border-radius: 8px;
  border: 1px solid var(--Border-color, #d0d5dd);
  background: #fbfcfe;
  height: 46px;

  .form-control {
    border: none;
    background-color: transparent;
    height: 100%;
    color: #162f3f;
    font-size: 15px;
    padding-left: 50px;
  }
  .flag-dropdown {
    border: none;
    background-color: transparent;
  }
  .selected-flag {
    padding: 0px 0px 0px 16px;
  }
}

.input-phone-bg-while {
  background: transparent !important;
}
