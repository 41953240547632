.who-we-serve {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  &__content {
    padding: 20px 40px 60px 40px;
    max-width: 1128px;
    margin: auto;
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap to the next row */
    gap: 30px; /* Optional: Adds spacing between items */
    justify-content: center;
  }
  .why-choose-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .who-we-serve {
    padding: 0px;
    &__content {
      padding: 40px 20px;
    }
  }
}
