.fade {
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.top-banner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  padding: 40px 0px 120px;
  overflow-x: clip;
  .animation-ellipse {
    left: 0px;
    position: absolute;
    transform: rotate(28.93deg);
    flex-shrink: 0;
    filter: blur(170px);
  }
  .animation-ellipse-bottom {
    position: absolute;
    bottom: -10%;
    transform: rotate(28.93deg);
    flex-shrink: 0;
    right: -5%;
    filter: blur(170px);
  }
  .pattern {
    position: absolute;
    left: 75%;
    top: 60%;
  }
  .banner-content {
    max-width: 1069px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    text-align: center;
    h2 {
      color: #0091ea;
      font-size: 45px;
      font-style: normal;
      font-weight: 700;
      line-height: 56px;
      overflow: hidden;
      display: inline-flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: center;
      span {
        display: inline-flex;
      }
    }
    h4 {
      color: var(--Text, #43aeef);
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: -0.144px;
    }
    p {
      color: var(--Text2, #667085);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 157.143% */
      letter-spacing: -0.084px;
    }
    .btn-get-started {
      position: relative;
      cursor: pointer;
      font-family: "Google Sans";
      margin-top: 15px;
      border-radius: 30px;
      background: #00bfa5;
      border: none;
      padding: 18px 60px;
      color: var(--White, #fff);
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
    }
  }
  .dashboard {
    width: 80%;
    .dashboard-image {
      max-width: 100%;
      height: auto;
      z-index: auto;
      border-radius: 20px;
      object-fit: cover;
    }
  }
}

.trusted-by {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding-bottom: 120px;
  h3 {
    color: var(--Text, #162f3f);
    /* H4 bold -24px */
    font-family: "Google Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 150% */
    letter-spacing: -0.144px;
    text-align: center;
    margin-bottom: 30px;
  }
  .trusted-by-logos {
    display: flex;
    justify-content: center;
    gap: 82px;
    align-items: center;
    flex-wrap: wrap;
    svg {
      // fill: var(--Text2, #667085);
      opacity: 0.8;
    }
  }
  span {
    color: var(--Text, #162f3f);
    /* Button medium - 16px */
    font-family: "Google Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }
}
// why choose

.why-choose {
  display: flex;
  width: 100%;
  max-width: 1440px;
  flex-direction: column;
  position: relative;
  gap: 40px;
  padding: 0px 0px 60px 40px;
  .why-choose-container {
    height: 100%;
  }
  .pattern {
    position: absolute;
    margin-left: -776px;
    opacity: 0.6;
  }
  h2 {
    color: var(--Text, #162f3f);
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px; /* 125% */
    letter-spacing: -0.288px;
    text-transform: capitalize;
  }
  .react-multi-carousel-list {
    order: 2;
  }

  .why-choose-item {
    display: flex;
    flex-direction: column;
    padding: 30px;
    margin: 10px;
    border-radius: 20px;
    height: 350px;
    h3 {
      color: var(--Text, #162f3f);
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 44px; /* 137.5% */
      margin-bottom: 30px;
    }
    .subtitle {
      color: #162f3f;
      margin-bottom: 4px;
    }
    p {
      color: var(--Text2, #667085);
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
  //custom arrow
  .custom-arrow {
    display: flex;
    justify-content: center;
    gap: 20px;
    order: 1;
    position: absolute;
    top: 0px;
    right: 40px;
    .back {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      border-radius: 50%;
      border: 1px solid #0091ea;
      cursor: pointer;
    }
    .next {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      border-radius: 50%;
      border: 1px solid #0091ea;
      background: #0091ea;
      cursor: pointer;
    }
  }
}
.arrow-why-choose {
  display: none !important;
}

.my-own-custom-container {
  display: flex;
  flex-direction: column;
  .react-multi-carousel-list {
    li {
      height: auto;
    }
    ul {
      height: 100%;
    }
  }
}
// real time news report
.real-time {
  padding: 120px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  background: var(--Grey-3, #f9f9f9);
  .title-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    h2 {
      color: #000;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 60px; /* 125% */
      letter-spacing: -0.288px;
      text-transform: capitalize;
    }
    p {
      color: var(--Text2, #667085);
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.12px;
    }
  }
  .view-report {
    max-height: 520px;
    display: flex;
    flex-direction: row;
    gap: 30px;
    .view-report-left {
      width: 253px;
      height: 480px;
      margin-top: 19px;
      flex-shrink: 0;
      opacity: 0.4;
    }
    .view-report-right {
      width: 253px;
      height: 480px;
      margin-top: 19px;
      flex-shrink: 0;
      opacity: 0.4;
    }
    .view-report-mid {
      position: relative;
      overflow-y: hidden;
      .dashboard-full {
        display: none;
      }
      .dashboard-chart {
        width: 903px;
      }
      .view-full-report {
        position: absolute;
        width: 100%;
        top: 346px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px 0px;
        gap: 20px;
        border-radius: 0px 0px 20px 20px;
        background: rgba(255, 255, 255, 0.4);
        backdrop-filter: blur(7.5px);
        p {
          color: var(--Text, #162f3f);
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.12px;
        }
        .btn-view-full-report {
          font-family: "Google Sans";
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          border: none;
          width: fit-content;
          padding: 12px 36px;
          border-radius: 30px;
          background: var(--primary-blue, #0091ea);
        }
      }
    }
  }
}
//key feature
.key-feature {
  overflow-x: clip;
  position: relative;
  padding: 60px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  max-width: 1440px;
  .pattern-left {
    position: absolute;
    margin-left: -776px;
    opacity: 0.6;
  }
  .animation-ellipse-left {
    position: absolute;
    transform: rotate(-3.752deg);
    z-index: 1;
    flex-shrink: 0;
    bottom: -30%;
    left: -8%;
    filter: blur(170px);
  }
  .pattern-right {
    position: absolute;
    right: -45%;
    top: 0%;
    opacity: 0.6;
  }
  .animation-ellipse-right {
    position: absolute;
    transform: rotate(168.821deg);
    flex-shrink: 0;
    top: 0%;
    right: -8%;
    filter: blur(170px);
  }
  h2 {
    color: var(--Text, #162f3f);
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px; /* 125% */
    letter-spacing: -0.288px;
    text-transform: capitalize;
    align-self: flex-start;
  }
  .key-feature-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px 28px;
    .key-feature-item {
      width: 40%;
      background: #ebf6fd;
      padding: 30px;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      gap: 45px;
      box-shadow: 0px 0px 223.4px -20px rgba(3, 145, 231, 0.09);
      .feature-icon {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
      .feature-content {
        .subtitle {
          font-weight: 500;
          margin-bottom: 4px;
          color: var(--Text, #162f3f);
        }
        h4 {
          color: #162f3f;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 36px; /* 150% */
          letter-spacing: -0.144px;
          margin-bottom: 8px;
        }
        .subtitle {
          color: #162f3f;
        }
        p {
          color: var(--Text2, #667085);
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.144px;
        }
      }
    }
  }
}
//form join
.form-join-container {
  padding: 120px 40px;
  .form-join-content {
    border-radius: 40px;
    // mix-blend-mode: color;
    padding: 100px 76px;
    border-radius: 40px;
    background: url(../../../assets/images/background_form_join.png) lightgray
      0px -17.333px / 100% 103.976% no-repeat;
    display: flex;
    align-items: center;
    gap: 80px;
    .form-join-text {
      display: flex;
      flex-direction: column;
      gap: 24px;
      max-width: 50%;
      h2 {
        color: var(--Text, #162f3f);
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 60px; /* 125% */
        letter-spacing: -0.288px;
        text-transform: capitalize;
      }
      p {
        color: var(--Text2, #667085);
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px; /* 150% */
        letter-spacing: -0.144px;
      }
    }
    .form-join-form {
      border-radius: 20px;
      background: #fff;
      padding: 30px;
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 24px;
      .form-title {
        display: flex;
        flex-direction: column;
        gap: 4px;
        h3 {
          color: var(--Text, #162f3f);
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 44px; /* 137.5% */
          letter-spacing: -0.192px;
        }
        p {
          color: var(--Text2, #667085);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
      .form-content {
        .form-content-item {
          display: flex;
          flex-direction: column;
          gap: 16px;
          .btn-get-started {
            cursor: pointer;
            font-family: "Google Sans";
            width: 100%;
            padding: 12px 36px;
            border-radius: 30px;
            background: var(--primary-blue, #0091ea);
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            border: none;
          }
        }
      }
    }
  }
}
//our latest
.our-latest {
  padding: 0px 40px 120px;
  display: flex;
  flex-direction: column;
  gap: 55px;
  .title-in-mobile {
    display: none;
    color: var(--Text, #162f3f);
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px; /* 137.5% */
    letter-spacing: -0.192px;
  }

  .our-latest-title {
    order: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    h2 {
      color: var(--Text, #162f3f);
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 60px; /* 125% */
      letter-spacing: -0.288px;
      text-transform: capitalize;
    }
  }
  .react-multi-carousel-list {
    order: 2;
  }
  .carosel-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    .react-multi-carousel-list {
      ul {
        gap: 30px;
      }
    }
  }
}
.our-latest-item {
  display: flex;
  flex-direction: column;
  gap: 20px;
  img {
    border-radius: 20px;
  }
  .item-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      color: var(--Text2, #667085);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
  h3 {
    color: var(--Text, #162f3f);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px; /* 137.5% */
    letter-spacing: -0.192px;
  }
}
//hear it from
.hear-it-from-container {
  overflow-x: clip;
  position: relative;
  padding: 0px 40px 120px;
  .pattern-left {
    position: absolute;
    height: 330px;
    bottom: -35%;
    left: -30%;
    opacity: 0.6;
  }
  .animation-ellipse-left {
    height: 100%;
    position: absolute;
    height: 330px;
    transform: rotate(-12.222deg);
    z-index: 1;
    flex-shrink: 0;
    bottom: -30%;
    left: -12%;
    filter: blur(170px);
  }
  .pattern-right {
    position: absolute;
    height: 330px;
    bottom: -40%;
    right: -30%;
    opacity: 0.6;
  }
  .animation-ellipse-right {
    position: absolute;
    transform: rotate(-58.287deg);
    flex-shrink: 0;
    top: 100%;
    right: -20%;
    filter: blur(170px);
  }
  .hear-it-content {
    border-radius: 40px;
    background: #ebf6fd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 100px 80px;
    h1 {
      color: var(--Text, #162f3f);
      font-size: 64px;
      font-style: normal;
      font-weight: 700;
      line-height: 80px; /* 125% */
      letter-spacing: -0.384px;
    }
    .hear-it-tag {
      position: relative;
      width: 657.491px;
      height: 600.779px;
      display: flex;
      justify-content: center;
      .tag-left {
        position: absolute;
        left: 0;
        top: 30px;
        width: 443.177px;
        height: 553.971px;
        transform: rotate(-5deg);
        flex-shrink: 0;
        border-radius: 40px;
        opacity: 0.6;
        background: #fff;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);
      }
      .tag-right {
        position: absolute;
        right: 0;
        top: 30px;
        width: 443.177px;
        height: 553.971px;
        transform: rotate(5deg);
        flex-shrink: 0;
        border-radius: 40px;
        opacity: 0.6;
        background: #fff;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);
      }
      .tag-mid {
        border-radius: 40px;
        background: #fff;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);
        position: relative;
        padding: 40px;
        width: 400px;
        border-radius: 40px;
        background: #fff;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .tag-rate {
          display: flex;
          flex-direction: column;
          gap: 40px;
          p {
            color: var(--Text, #162f3f);
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 40px;
          }
        }
        .tag-author {
          display: flex;
          gap: 20px;
          align-items: center;
          .author-content {
            display: flex;
            flex-direction: column;
            gap: 5px;
            h5 {
              color: var(--Text, #162f3f);
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 28px; /* 140% */
              letter-spacing: -0.2px;
            }
            p {
              color: var(--Text2, #667085);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 157.143% */
              letter-spacing: -0.084px;
            }
          }
        }
      }
      .arrow {
        position: absolute;
        top: 50%;
        width: 110%;
        display: flex;
        justify-content: space-between;
        .btn-arrow-back {
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 20px;
          background: var(--primary-blue, #0091ea);
          border-radius: 50%;
        }
        .btn-arrow-next {
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 20px;
          background: var(--primary-blue, #0091ea);
          border-radius: 50%;
        }
      }
    }
  }
}

@media only screen and (min-width: 464px) and (max-width: 1024px) {
  .top-banner {
    .dashboard {
      width: 80%;
    }
  }
  .real-time {
    .view-report {
      .view-report-mid {
        .dashboard-chart {
          width: 600px;
        }
      }
    }
  }
  .hear-it-from-container {
    padding: 40px 20px;
    .pattern-left {
      display: none;
    }
    .animation-ellipse-left {
      display: none;
    }
    .pattern-right {
      display: none;
    }
    .animation-ellipse-right {
      height: 300px;
      top: 115%;
      right: -40%;
      filter: blur(90px);
    }
    .hear-it-content {
      border-radius: 20px;
      padding: 20px;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      h1 {
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 44px; /* 137.5% */
        letter-spacing: -0.192px;
        text-align: center;
      }
      .hear-it-tag {
        width: 323.044px;
        height: fit-content;
        .tag-left {
          left: 5%;
          top: 10px;
          width: 225.804px;
          height: 282.256px;
        }
        .tag-right {
          right: 5%;
          top: 10px;
          width: 225.804px;
          height: 282.256px;
        }
        .tag-mid {
          justify-content: unset;
          gap: 15px;
          width: 200px;
          height: unset;
          padding: 20px;
          .tag-rate {
            display: flex;
            flex-direction: column;
            gap: 15px;
            svg {
              width: 75.009px;
              height: 11.21px;
            }
            p {
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
            }
          }
          .tag-author {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            img {
              width: 30px;
            }
            .author-content {
              gap: 2px;
              h5 {
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
              }
              p {
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
              }
            }
          }
        }
        .arrow {
          display: none;
          .btn-arrow-back {
            display: flex;
            align-items: center;
            padding: 20px;
            background: var(--primary-blue, #0091ea);
            border-radius: 50%;
          }
          .btn-arrow-next {
            display: flex;
            align-items: center;
            padding: 20px;
            background: var(--primary-blue, #0091ea);
            border-radius: 50%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .top-banner {
    gap: 30px;
    padding: 40px 20px !important;
    .banner-content {
      h2 {
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        overflow: hidden;
        letter-spacing: -0.192px;
        display: inline-flex;
        flex-wrap: wrap;
        span {
          display: inline-block;
        }
      }

      h4 {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 140% */
        letter-spacing: -0.2px;
        text-align: center;
      }
      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 157.143% */
        letter-spacing: -0.084px;
      }
    }
    .dashboard {
      width: 100%;
    }
    .animation-ellipse-bottom {
      display: none;
    }
    .animation-ellipse {
      display: none;
    }
  }
  .react-multi-carousel-track {
    gap: 0px;
  }
  .why-choose-container {
    padding: 0px !important;
  }
  .why-choose {
    padding: 20px !important;
    gap: 30px;
    max-width: 100%;
    .pattern {
      display: none;
    }
    h2 {
      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 44px; /* 137.5% */
      letter-spacing: -0.192px;
      text-align: center;
    }
  }
  .why-choose-item {
    padding-right: 30px;
    height: 500px !important;
    h3 {
      padding: 20px 0px 0px 20px;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px; /* 150% */
      letter-spacing: -0.144px;
    }
    p {
      padding: 0px 20px 20px 30px;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.12px;
    }
  }

  .arrow-why-choose {
    display: flex !important;
  }
  .custom-arrow {
    gap: 15px;
    bottom: 0;
    width: 100%;
    right: initial !important;
    top: initial !important;
    .back {
      padding: 15px;
    }
    .next {
      padding: 15px;
    }
  }

  .real-time {
    position: relative;
    padding: 40px 20px;
    .title-content {
      h2 {
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 44px; /* 137.5% */
        letter-spacing: -0.192px;
        text-align: center;
      }
      p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        letter-spacing: -0.084px;
        text-align: center;
      }
    }
    .view-report {
      .view-report-left {
        display: none;
      }
      .view-report-right {
        display: none;
      }
      .view-report-mid {
        position: relative;
        overflow-y: unset;
        display: flex;
        flex-direction: column;
        align-items: center;
        .dashboard-full {
          display: unset;
          width: 300px;
          flex-shrink: 0;
          border-radius: 10px;
          border: 1px solid #ebebeb;
        }
        .dashboard-chart {
          margin-top: -45%;
          width: 100%;
          border-radius: 8px;
        }
        .view-full-report {
          position: relative;
          width: unset;
          top: unset;
          margin-top: -10%;
          padding: 10px 20px;
          border-radius: 0px 0px 20px 20px;
          background: rgba(255, 255, 255, 0.4);
          backdrop-filter: blur(7.5px);
          p {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            text-align: center;
          }
        }
      }
    }
  }
  .key-feature {
    padding: 116px 20px 40px 20px;
    gap: 30px;
    .pattern-left {
      display: none;
    }
    .animation-ellipse-left {
      display: none;
    }
    .pattern-right {
      display: none;
    }
    .animation-ellipse-right {
      display: none;
    }
    h2 {
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 44px; /* 137.5% */
      letter-spacing: -0.192px;
      text-align: center;
    }
    .key-feature-container {
      gap: 20px 0px;
      .key-feature-item {
        width: 100%;
        padding: 20px;
        gap: 20px;
        .feature-content {
          h4 {
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
            height: fit-content;
          }
          p {
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.12px;
          }
          span {
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.12px;
          }
        }
      }
    }
  }
  .form-join-container {
    padding: 0px 0px !important;
    padding: unset;
    .form-join-content {
      flex-shrink: 0;
      flex-direction: column;
      border-radius: unset;
      padding: 40px 20px;
      gap: 30px;
      .form-join-text {
        max-width: 100%;
        h2 {
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 44px; /* 137.5% */
          letter-spacing: -0.192px;
          text-align: center;
        }
        p {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
          letter-spacing: -0.084px;
          text-align: center;
        }
      }
      .form-join-form {
        width: unset;
        padding: 20px;
      }
    }
  }
  .our-latest {
    padding: 40px 20px;
    gap: 20px;
    .title-in-mobile {
      display: unset;
    }
    // .my-own-custom-container {
    //   gap: 30px;
    //   .our-latest-title {
    //     justify-content: center;
    //     h2 {
    //       display: none;
    //     }
    //     order: 2;
    //   }
    //   .react-multi-carousel-list {
    //     order: 1;
    //   }
    // }
  }
  .our-latest-item {
    padding: 0px !important;
    max-width: 375px;
    .item-date {
      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
    h3 {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }
  }
  .hear-it-from-container {
    padding: 40px 20px;
    .pattern-left {
      display: none;
    }
    .animation-ellipse-left {
      display: none;
    }
    .pattern-right {
      display: none;
    }
    .animation-ellipse-right {
      height: 300px;
      top: 115%;
      right: -40%;
      filter: blur(90px);
    }
    .hear-it-content {
      border-radius: 20px;
      padding: 20px;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      h1 {
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 44px; /* 137.5% */
        letter-spacing: -0.192px;
        text-align: center;
      }
      .hear-it-tag {
        width: 323.044px;
        height: fit-content;
        .tag-left {
          left: 5%;
          top: 10px;
          width: 225.804px;
          height: 282.256px;
        }
        .tag-right {
          right: 5%;
          top: 10px;
          width: 225.804px;
          height: 282.256px;
        }
        .tag-mid {
          justify-content: unset;
          gap: 15px;
          width: 200px;
          height: unset;
          padding: 20px;
          .tag-rate {
            display: flex;
            flex-direction: column;
            gap: 15px;
            svg {
              width: 75.009px;
              height: 11.21px;
            }
            p {
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
            }
          }
          .tag-author {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            img {
              width: 30px;
            }
            .author-content {
              gap: 2px;
              h5 {
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
              }
              p {
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
              }
            }
          }
        }
        .arrow {
          display: none;
          .btn-arrow-back {
            display: flex;
            align-items: center;
            padding: 20px;
            background: var(--primary-blue, #0091ea);
            border-radius: 50%;
          }
          .btn-arrow-next {
            display: flex;
            align-items: center;
            padding: 20px;
            background: var(--primary-blue, #0091ea);
            border-radius: 50%;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .why-choose-item {
    height: 500px !important;
  }
  .form-join-content {
    padding: 40px 20px !important;
  }
}
