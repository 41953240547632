.time-picker {
  :global {
    .ant-picker-input > input {
      color: #162f3f;
      font-family: "Google Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
  }
}
:global(.ant-picker-dropdown) {
  z-index: 105000; /* or any higher value you need */
}
