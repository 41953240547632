.custom-date-range-picker {
  height: 48px;
  max-width: 330px;
  .ant-picker-input > input {
    color: #162f3f;
    font-family: "Google Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    padding-left: 30px !important;
  }

  .ant-picker-suffix {
    position: absolute;
    left: 2;
    right: auto;
  }
}

.custom-date-range-picker-mobile {
  max-width: 100% !important;
  min-width: 100%;
}
