.join-bond-vexity {
  display: flex;
  padding: 60px;
  align-items: center;
  margin: 0 40px;
  gap: 30px;
  flex: 1 0 0;
  max-width: 1440px;
  background-image: url(../../../../assets/images/about-us/join-bondvexity-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(255, 255, 255, 0.9);
  background-blend-mode: overlay;
  border-radius: 40px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  &__title {
    color: var(--Text, #0091ea);
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -0.288px;
    text-transform: capitalize;
    margin-bottom: 16px;
  }
  &__description {
    color: var(--Text2, #667085);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 340px;
    object-fit: cover;
    flex-shrink: 0;
    border-radius: 40px;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0) -16.61%,
      #fff 67.32%
    );
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);
  }
}

@media screen and (max-width: 928px) {
  .join-bond-vexity {
    padding: 40px;
    gap: 20px;
    margin: 60px 20px 0 20px;
    flex-direction: column;

    &__title {
      font-size: 32px;
      line-height: 44px;
    }
    &__description {
      font-size: 16px;
      line-height: 24px;
    }
    .image-container {
      width: 100%;
      height: 261px;
    }
  }
}
