.core-value-container {
  width: 100%;
  display: flex;
  justify-content: center;
  .core-value {
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    gap: 40px;
    padding: 60px 40px;
    > h2 {
      color: var(--Text, #0091ea);
      font-size: 48px;
      font-weight: 700;
      line-height: 60px;
      margin-bottom: 15px;
    }
    &__body {
      display: flex;
      gap: 30px;
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }

    &__item {
      display: flex;
      gap: 32px;
      &-no {
        display: flex;
        min-width: 64px;
        height: 64px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 10px;
        background: var(--Grey-3, #f9f9f9);
        text-align: center;
        span {
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 36px;
          letter-spacing: -0.144px;
          background: var(
            --primary-g,
            linear-gradient(172deg, #00bfa5 36.09%, #0091ea 93.19%)
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .core-value-container {
    .core-value {
      flex-direction: column;
      gap: 20px;
      padding: 40px 20px;
      > h2 {
        font-size: 32px;
        line-height: 44px;
        text-align: center;
      }
      &__body {
        display: flex;
        flex-direction: column;
        gap: 20px;
        > img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }
      &__content {
        gap: 20px;
      }
      &__item {
        gap: 16px;
        max-width: 100%;
        &-no {
          min-width: 48px;
          height: 48px;
          span {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1028px) {
  .core-value {
    img {
      width: 100%;
      height: auto !important;
      object-fit: fill !important;
    }
  }
}
