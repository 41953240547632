.company {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .company-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h5 {
      color: var(--Text, #162f3f);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px; /* 140% */
      letter-spacing: -0.2px;
    }
    .header-actions {
      display: flex;
      gap: 20px;
    }
  }
}

.custom-table {
  border-radius: 8px;
  background-color: #ffffff;
  .ant-table-wrapper .ant-table-thead > tr > th {
    background: #f9f9f9;
    color: #71717a;
    font-size: 14px;
    font-weight: 700;
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    color: #667085;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
  .ant-pagination {
    padding-right: 16px;
  }
  .ant-table-row {
    cursor: pointer;
  }
  .ant-table-cell {
    padding: 8px !important;
  }
  .MuiSheet-root {
    padding: 0 !important;
  }
  tr {
    cursor: pointer;
  }
  // .ant-table-wrapper td.ant-table-column-sort {
  //   background: transparent;
  // }
}

.filter-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .btn-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 16px;
    button {
      width: 50%;
    }
  }
}
.custom-tabs {
  // margin-top: 12px;
  .MuiTab-root {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #667085;
  }
}

// .even-row {
//   background-color: rgb(
//     0 145 234 / 10%
//   ); /* Change this to your desired color */
// }
