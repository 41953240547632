.top-banner {
  //   background: linear-gradient(35deg, #00bfa5 100%, #0091ea 100%);
  // background-color: linear-gradient(180deg, #00bfa5 0%, #0091ea 100%);
  //   background: linear-gradient(135deg, #00bfa5 0%, #0091ea 100%);
  //   background: rgba(3, 145, 231, 0.09);
  display: flex;
  align-items: center;
  flex-direction: column;
  &__content {
    padding: 0 40px;
    display: flex;
    justify-content: center;
    max-width: 1440px;
    // align-items: centere;
    gap: 30px;
    &-right {
      display: flex;
      flex-direction: column;
      gap: 30px;

      &-top {
        display: flex;
        gap: 30px;
      }
    }
  }
}
// }

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .top-banner {
    &__content {
      :global(.custom-card:first-child) {
        display: flex;
        flex-direction: row !important;
        max-width: 100% !important;
        gap: 30px;
        img {
          width: 400px !important;
          height: 100% !important;
          object-fit: cover !important;
          margin-bottom: unset !important;
        }
      }
      flex-direction: column;
      padding: 0 30px;
      gap: 20px;
      &-right {
        gap: 20px;
        :global(.custom-card) {
          .card-image-container {
            max-width: 100%;
            img {
              width: 100% !important;
              height: auto;
              object-fit: cover;
            }
          }
        }
        &-top {
          gap: 20px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .top-banner {
    &__content {
      padding: 0 20px;
      flex-direction: column;
      gap: 20px;
      &-right {
        gap: 20px;

        :global(.custom-card) {
          img {
            width: 100% !important;
            height: auto;
            object-fit: cover;
          }
        }
        &-top {
          gap: 20px;
          flex-direction: column;
        }
      }
    }
  }
}
