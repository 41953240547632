.setting-item {
  padding-bottom: 30px;
  padding-top: 30px;
  border-bottom: 1px solid var(--Grey-2, #f5f5f5);
}
.setting-description {
  display: flex;
  flex-direction: column;
  gap: 20px;
  h5 {
    color: var(--Text, #162f3f);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 140% */
    letter-spacing: -0.2px;
  }
  &__header {
    justify-content: space-between;
    display: flex;
    gap: 16px;
  }
}

.subtitle {
  color: var(--Text2, #667085);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 10px;
}

.notification-item {
  display: flex;
  justify-content: space-between;
  &-title {
    display: inline-block;
  }
}

.notification-item-buttons {
  display: flex;
  gap: 12px;
  margin-top: 20px;
}

.notification-item-title {
  display: inline-block;
  color: var(--Text, #162f3f);
}
