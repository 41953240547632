.research-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
  padding-left: 30px;
  .info {
    display: flex;
    gap: 12px;
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: #667085;
    }
  }
  .actions-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icons-container {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
}

.recent-research {
  margin-top: 16px;
  padding: 16px 32px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .header-title {
    color: #162f3f;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px; /* 140% */
    margin-bottom: 20px;
  }
  .item {
    padding: 8px 16px;
    border-radius: 10px;
    background: #f9f9f9;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .item:hover {
    background: rgb(0 145 234 / 10%);
  }
  .content {
    display: flex;
    flex-direction: column;
  }
  .label {
    font-size: 16px;
    font-weight: 700;
    color: #162f3f;
    line-height: 24px;
    margin-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 600px;
  }
  .type {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #667085;
    border-left: 1px solid rgb(208, 213, 221);
    padding-left: 12px;
  }
  .date {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #667085;
    padding-right: 12px;
  }
  .dot {
    background: #667085;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin-left: 6px;
    margin-right: 6px;
  }
  .content {
    display: flex;
  }
}

.icon-back {
  display: flex;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  height: 36px;
  width: 36px;
  border-radius: 99%;
}
.research-name {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  font-size: 28px;
  font-weight: 700;
  color: #0081d0;
  line-height: 42px;
  margin-bottom: 10px;
  font-family: "Google Sans";
}

.research-name-underline {
  padding-bottom: 5px;
  border-bottom: 2px solid rgb(224, 224, 224);
}

.user-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.footer {
  margin-top: 40px;
}
