body {
  margin: 0;
  height: 100vh;
}
#root{
  height: 100%;
}
.pattern-top{
  position: absolute;
  margin-left:-776px;
  opacity: 0.6
}
