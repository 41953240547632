.card {
  max-width: 40%;
  img {
    margin-bottom: 32px;
  }

  h2 {
    color: var(--Text, #162f3f);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.192px;
    margin-bottom: 16px;
  }
  p {
    color: var(--Text2, #667085);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

@media (max-width: 768px) {
  .card {
    max-width: 100% !important;
    flex-direction: column;
    h2 {
      font-size: 24px;
      line-height: 36px;
    }
    // img {
    //   width: 100%;
    //   height: auto;
    //   object-fit: cover;
    // }
  }
}
