.life-at-bondvexity {
  max-width: 1440px;
  margin: 60px 0px;
  padding: 0px 20px;
  &__content {
    display: flex;
    gap: 40px;
    justify-content: center;
    align-items: center;
  }
  &__content {
    display: flex;
    padding: 60px;
    gap: 40px;
    border-radius: 40px;
    background: #ebf6fd;
  }
  &__left {
    display: flex;
    flex-direction: column;
    gap: 32px;
    .text {
      display: flex;
      flex-direction: column;
      gap: 16px;
      > h2 {
        color: var(--Text, #162f3f);
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: -0.288px;
        text-transform: capitalize;
      }
      p {
        color: var(--Text2, #667085);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
    > button {
      cursor: pointer;
      width: fit-content;
      border: none;
      padding: 12px 36px;
      border-radius: 30px;
      background: var(--primary-blue, #0091ea);
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
  }
  &__right {
    display: flex;
  }
}

@media only screen and (max-width: 967px) {
  .life-at-bondvexity {
    padding: 0px;
    margin: 0px;
    &__content {
      flex-direction: column;
      gap: 30px;
      padding: 40px 20px;
      border-radius: 0;
    }
    &__left {
      .text {
        align-items: center;
        text-align: center;
        text-wrap: balance;
        > h2 {
          font-size: 32px;
          line-height: 44px;
        }
      }
      align-items: center;
      > button {
        width: 100%;
      }
    }
    &__right {
      .img-left {
        max-width: 50%;
        height: auto;
      }
      .img-right {
        max-width: 50%;
        height: auto;
      }
    }
  }
}
