.banner {
  max-width: 1024px;
  text-align: center;
  margin: auto;
  padding: 60px 40px;
  h2 {
    color: var(--Text, #0091ea);
    text-align: center;
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;
    margin-bottom: 15px;
  }
  p {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 157.143% */
    letter-spacing: -0.084px;
    color: var(--Text2, #667085);
  }
}

@media (max-width: 768px) {
  .banner {
    padding: 40px 20px;
    h2 {
      font-size: 32px;
      line-height: 44px;
    }
    p {
      margin-left: 20px;
      margin-right: 20px;
      font-size: 14px;
      line-height: 22px;
    }
  }
}
