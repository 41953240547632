.settings{
    display: flex;
    flex-direction: column;
    gap: 16px;
    h5{
        color: var(--Text, #162F3F);
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 140% */
        letter-spacing: -0.2px;
    }
    .settings-container{
        display: flex;
        gap: 20px;
    }
}
@media only screen and (max-width: 430px) {
    .settings{
        gap: 20px;
        .settings-container{
            flex-direction: column;
            gap: 10px;
        }
    }
}