.container {
  width: 100%;
  max-width: 1440px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 40px;
  margin: 0 40px;
  .why-work-at {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 40px;
    width: 100%;
    > h2 {
      color: var(--Text, #0091ea);
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 60px;
      letter-spacing: -0.288px;
      text-transform: capitalize;
      // padding: 0 40px;
    }

    .custom-carousel-container {
      // padding: 0 40px;
      display: flex;
      flex-direction: column;
      :global(.react-multi-carousel-list) {
        li {
          height: auto;
        }
        ul {
          height: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    margin: 0 20px;
    .why-work-at {
      gap: 20px;
      > h2 {
        padding: 0 20px;
        font-size: 32px;
        line-height: 44px;
        text-align: center;
      }

      .custom-carousel-container {
        padding: 0 20px;
      }
    }
  }
}
