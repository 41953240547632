.opportunities {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: #f9f9f9;
  width: 100%;
  // margin-top: 50px;
  &-content {
    max-width: 1440px;
    padding: 0px 20px;
    &__bottom {
      text-align: center;
      font-style: normal;
      margin-bottom: 60px;
      > h4 {
        margin-top: 40px;
        color: var(--Text, #162f3f);
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: -0.144px;
      }
      p {
        margin-top: 20px;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        color: var(--Text2, #667085);
      }
    }
  }
  &-card__content {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .opportunities {
    &-content {
      padding: 0 20px;
      max-width: 100%;

      &__bottom {
        margin-bottom: 40px;
        text-wrap: balance;
      }
    }
  }
}
