.cms-menu-container {
  height: 100%;
  padding: 0px;
  transition: all 0.3s;
  .cms-menu-body {
    height: 100%;
    box-shadow: 8px 0px 18.2px 0px rgba(22, 47, 63, 0.03);
    border-radius: 0px 20px 20px 0px;

    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f0f6fa;
    justify-content: space-between;
    border-right: 1px solid rgb(224, 224, 224);
  }

  .top-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    .collapse-btn {
      position: absolute !important;
      top: 28px !important;
      right: -14px !important;
      cursor: pointer !important;
      border-radius: 9999px !important;
      background: #f9f9f9 !important;
      min-width: 0;
      min-height: 0;
      padding: 6px;
      border: 1px solid rgb(224, 224, 224);
    }
    .collapse-btn:hover {
      background: #dff2ff !important;
      fill: #0091ea;
    }
  }

  .menu-active {
    background: #0091ea !important;
    p {
      color: #ffffff !important;
    }
  }
  .menu-active-my-content {
    background: #0091ea !important;
    svg path {
      stroke: #ffffff !important;
    }
    p {
      color: #ffffff !important;
    }
  }
  .menu-item {
    cursor: pointer;
    position: relative;
    // padding: 12px 0 12px 0;
    height: 56px;
    display: flex;
    // gap: 16px;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 13px;
    p {
      color: var(--Text2, #667085);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 157.143% */
      margin-left: 16px;
    }
    .line {
      position: absolute;
      left: 0px;
      width: 3px;
      height: 30px;
      background: #0091ea;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }
  .menu-item:hover {
    background: #f0f0f0;
  }
}
.hover-card:hover {
  background: #f9f9f9;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.user-popover {
  // border: 1px solid #e0e0e0;
  width: 310px;
  .header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 12px;
    .fullName {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      color: #162f3f;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 250px;
    }
    .email {
      margin-top: 4px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      color: #667085;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 250px;
    }
  }
  .content {
    .item {
      margin-top: 12px;
      display: flex;
      align-items: center;
      height: 36px;
      padding-left: 8px;
      padding-right: 8px;
      cursor: pointer;
      border-radius: 12px;
      gap: 5px;
      p {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        color: #667085;
      }
    }
    .item:hover {
      background: #f9f9f9;
    }
  }
}

@media only screen and (max-width: 430px) {
  .cms-menu {
    display: none;
  }
}
@media only screen and (min-width: 430px) and (max-width: 1024px) {
  .cms-menu {
    display: none;
  }
}
