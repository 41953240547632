.custom-arrow {
  display: flex;
  justify-content: center;
  gap: 20px;
  order: 1;
  position: absolute;
  top: 0px;
  right: 40px;
  .back {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 50%;
    border: 1px solid #0091ea;
    cursor: pointer;
  }
  .next {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 50%;
    border: 1px solid #0091ea;
    background: #0091ea;
    cursor: pointer;
  }
}

@media only screen and (max-width: 768px) {
  .custom-arrow {
    gap: 15px;
    bottom: -76px;
    width: 100%;
    right: initial !important;
    top: initial !important;
    .back {
      padding: 15px;
    }
    .next {
      padding: 15px;
    }
  }
}
