.about-bondvexity {
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding: 60px 40px;
  &__grid {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
    // flex: 1 0 0;
  }
}

@media (max-width: 768px) {
  .about-bondvexity {
    flex-direction: column;
    gap: 30px;
    flex: 1;
    padding: 0 20px;
  }
}
