.container {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  padding: 20px;
  background-color: #f3f5f6;
  min-height: 100vh;
  max-width: 60%;
  min-width: 728px;
  margin: 0 auto;
  .article-title {
    > p {
      color: #162f3f;
      font-size: 28px;
      font-weight: 700;
      line-height: 28px;
    }
    margin-bottom: 15px;
  }
  .article-info {
    display: flex;
    align-items: center;
    > p {
      font-size: 16;
      font-weight: 400;
      line-height: 1.6rem;
      color: #667085;
      margin-left: 15px;
    }
    .article-actions-container {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-left: 15px;
      // padding-left: 15px;
      // border-left: 1px solid #d0d5dd;
    }
  }

  .block-container {
    background-color: #ffffff;
    border-radius: 10px;
    margin-top: 24px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .summary-container {
    margin-bottom: 20px;
    background-color: #ffffff;
    padding: 16px 32px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin-top: 17px;
    &__title {
      color: "#162f3f";
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 12px;
    }
    &__description {
      color: "#667085";
      font-size: 14px;
      font-style: italic;
      padding-bottom: 16px;
    }
  }
}
.footer {
  margin-top: 40px;
  // background-color: #ffffff;
  padding: 0px 0 2px 0;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.research-name {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  font-size: 28px;
  font-weight: 700;
  color: #0081d0;
  line-height: 42px;
  margin-bottom: 10px;
  font-family: "Google Sans";
}

.research-subtitle {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  color: #667085;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 32px;
  font-family: "Google Sans";
  line-height: 28px;
}

@media screen and (max-width: 762px) {
  .container {
    max-width: 100%;
    width: initial;
    .block-container {
      max-width: 100%;
    }
  }
}
