@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
/* cyrillic */
@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/googlesans/v27/4UaGrENHsxJlGDuGo1OIlL3Kwp5MKg.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/googlesans/v27/4UaGrENHsxJlGDuGo1OIlL3Nwp5MKg.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/googlesans/v27/4UaGrENHsxJlGDuGo1OIlL3Bwp5MKg.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/googlesans/v27/4UaGrENHsxJlGDuGo1OIlL3Awp5MKg.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/googlesans/v27/4UaGrENHsxJlGDuGo1OIlL3Owp4.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/googlesans/v27/4UabrENHsxJlGDuGo1OIlLU94Yt3CwZ-Pw.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/googlesans/v27/4UabrENHsxJlGDuGo1OIlLU94YtwCwZ-Pw.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/googlesans/v27/4UabrENHsxJlGDuGo1OIlLU94Yt8CwZ-Pw.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/googlesans/v27/4UabrENHsxJlGDuGo1OIlLU94Yt9CwZ-Pw.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/googlesans/v27/4UabrENHsxJlGDuGo1OIlLU94YtzCwY.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

:root {
  --warning: #fbc02d;
  --danger: #d32f2f;
  --success: #388e3c;
  --info: #0288d1;
  --disable: #404040;
  --back: #14303f;
  --gray: #cccccc;
  --item-active: #cccccc;
  --backgound-color-thead-table: rgb(5, 26, 90);
  --text-color-thead-table: white;
  --primary-color: #e89965;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1em;
  font-family: "Google Sans", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin-bottom: 0px;
  margin-top: 0px;
  // font-size: 14px;
  line-height: 20px;
}
h1,
h2,
h3,
h4,
h5 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.css-1gw9vc6-JoyInput-input {
  font-family: "Google Sans" !important;
}
.css-1ckvkg0-JoyFormLabel-root {
  font-family: "Google Sans" !important;
  color: var(--Text, #162f3f) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 22px !important; /* 157.143% */
  letter-spacing: -0.084px !important;
  margin-bottom: 6px !important;
}
.css-540a77-JoyInput-root {
  width: 100% !important;
  padding: 5px 16px !important;
  border-radius: 8px;
  background: var(--White, #fff);
}
.css-kf85qm-JoyInput-root {
  padding: 11px 16px !important;
  border-radius: 8px;
  background: var(--White, #fff);
  box-shadow: none !important;
}
.css-1hr8yhh-JoySelect-button {
  color: var(--Text, #162f3f) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}
.css-whi0zy-JoySelect-root {
  padding: 13px 16px !important;
  border-radius: 8px;

  background: var(--White, #fff);
}
.css-me27eb-JoySelect-button {
  font-family: "Google Sans" !important;
}
.Mui-focused {
  &::before {
    border: 1px solid #0091ea;
  }
}
.Mui-expanded::before {
  border: 1px solid var(--primary-blue, #0091ea) !important;
}
.css-12fhx3c-JoySelect-root {
  padding: 0 !important;
  &:hover {
    background: none !important;
  }
}
.css-b0m4zx-JoySelect-indicator {
  width: 20px;
}
// .css-nnbxuc-JoyInput-startDecorator{
//   width: 20px;
// }
// .css-ki6l96-JoyInput-endDecorator{
//   width: 20px;
// }
.css-137agis-JoyCheckbox-input {
  position: unset !important;
}
.css-n82b39-JoyCheckbox-checkbox {
  color: white !important;
  background-color: white !important;
  border: 1px solid #d0d5dd;
}
.Mui-checked .css-n82b39-JoyCheckbox-checkbox {
  background-color: #0091ea !important;
}
.css-borvem-JoyCheckbox-root {
  color: var(--gray-900, #18181b) !important;
  font-family: "Google Sans" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 22px !important; /* 157.143% */
  letter-spacing: -0.084px !important;
}
.css-whi0zy-JoySelect-root:not([data-skip-inverted-colors]) {
  --Input-focusedHighlight: #0091ea !important;
}
.css-kf85qm-JoyInput-root:not([data-skip-inverted-colors]) {
  --Input-focusedHighlight: white !important;
}
.css-540a77-JoyInput-root:not([data-skip-inverted-colors]) {
  --Input-focusedHighlight: white !important;
}

.ant-menu-light.ant-menu-root.ant-menu-inline,
.ant-menu-light > .ant-menu.ant-menu-root.ant-menu-inline,
.ant-menu-light.ant-menu-root.ant-menu-vertical,
.ant-menu-light > .ant-menu.ant-menu-root.ant-menu-vertical {
  border-inline-end: none;
}
.ant-menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.ant-menu-title-content {
  color: var(--Text2, #667085);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.ant-menu-item-selected {
  border-radius: 39px !important;
  background: rgba(208, 213, 221, 0.2) !important;
  .ant-menu-title-content {
    color: var(--Text, #162f3f);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}
.ant-menu-item {
  padding: 12px 15px !important;
  margin: 0 !important;
  height: unset !important;
  &:hover {
    border-radius: 39px !important;
  }
}
.main-content {
  min-height: 60vh;
  margin-top: 80px;
  background-color: #fff;
}
@media only screen and (max-width: 900px) {
  .main-content {
    overflow-x: hidden;
    margin-top: 60px;
  }
}
.ant-drawer {
  // top: 0 !important;
}

.ant-drawer-header {
  // background-color: #5340ff;
  background-color: #fff;
  border-radius: 0;
  .ant-drawer-header-title {
    flex-direction: row-reverse !important;
  }
}

.ant-drawer-title {
  color: #fff;
}
.ant-drawer-content {
  z-index: 100000 !important;
}

.ant-drawer-body {
  background-color: #fff;
}

.ant-drawer-mask {
  background-color: rgba(0, 0, 0, 0.45) !important;
  z-index: 100 !important;
}

.label-text {
  color: var(--Text, #162f3f);
  /* Button medium  - 16px */
  font-family: "Google Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.label-checkbox {
  color: var(--Text, #162f3f);
  /* B6 regular - 14px */
  font-family: "Google Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.084px;
}

.custom-table-pagination .ant-pagination-item a {
  color: black !important; /* Custom text color */
  border-radius: 9999px;
}

.custom-table-pagination {
  .ant-pagination-item {
    border-radius: 9999px;
  }
  .ant-pagination-item-active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 9999px;
    background-color: #0091ea !important; /* Custom active page background color */
    a {
      color: white !important;
    }
  }
  .ant-pagination-next,
  .ant-pagination-prev {
    button {
      border-radius: 9999px !important;
    }
  }
}
