.solutions {
  .body-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    margin-bottom: 60px;
    padding-left: 40px;
    padding-right: 40px;
    .session-container {
      display: flex;
      gap: 60px;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      .side-image {
        padding: 40px;
        border-radius: 16px;
        img {
          max-width: 100%;
          height: auto;
        }
      }
    }
    .session-container:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
  .why-choose-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
