.upload-content {
  width: 100%;
  height: 160px;
  border-radius: 20px;
  border: 1px dashed var(--Border-color, #d0d5dd);
  background: var(--Shades-White, #fff);
  display: flex;
  align-items: center;
  justify-content: center;
  .choose-upload {
    display: flex;
    gap: 1px;
    .btn-choose {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 4px;
      background: var(--Input-border-hover, #b3b7c2);
      &:hover {
        background: var(--Text2, #667085);
      }
    }
    .btn-choose-left {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 4px;
      border-radius: 6px 0px 0px 6px;
      background: var(--Input-border-hover, #b3b7c2);
      &:hover {
        background: var(--Text2, #667085);
      }
    }
    .btn-choose-right {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 4px;
      border-radius: 0px 6px 6px 0px;
      background: var(--Input-border-hover, #b3b7c2);
      &:hover {
        background: var(--Text2, #667085);
      }
    }
  }
}
.text-upload {
  position: relative;
  width: 100%;
  // padding: 40px;
  border: 1px dashed var(--Border-color, #d0d5dd);
  background: var(--Shades-White, #fff);
  border-radius: 20px;
  p {
    line-height: 24px;
  }
  h1 {
    line-height: 46px;
  }
}
.header {
  .ql-editor {
    color: var(--Text, #162f3f);
    font-family: "Google Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px; /* 150% */
    // letter-spacing: -0.192px;
  }
  h1 {
    line-height: 76px !important;
  }
}
.body {
  .ql-editor {
    color: var(--Text, #162f3f);
    font-family: "Google Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
.rich-text {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  padding: 11px 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid var(--Border-color, #d0d5dd);
  border-radius: 8px;

  p,
  h4,
  h5,
  h6 {
    font-size: 16px !important;
    font-family: "Google Sans";
    color: #2c3e50;
    line-height: 24px;
    margin-bottom: 16px;
  }
  h1 {
    font-family: "Google Sans";
    color: #0091ea;
    font-size: 22px !important;
    border-bottom: 1px solid #b2ebf2;
    margin-bottom: 12px;
    line-height: 33px;
    font-weight: 700;
    padding-bottom: 8px;
    margin-top: 32px;
  }
  h1:first-of-type {
    margin-top: 0px;
    padding-bottom: 0px;
    border-bottom: none;
  }
  h2:first-of-type {
    margin-top: 0px;
  }
  h3:first-of-type {
    margin-top: 0px;
  }
  h2 {
    font-family: "Google Sans";
    font-size: 20px !important;
    color: #0091ea;
    line-height: 30px;
    margin-bottom: 12px;
    font-weight: 700;
    margin-top: 32px;
  }
  h3 {
    font-family: "Google Sans";
    font-size: 18px !important;
    color: #0091ea;
    line-height: 27px;
    margin-bottom: 12px;
    font-weight: 700;
    margin-top: 32px;
  }
  a {
    color: #1677ff !important;
    font-family: "Google Sans";
  }
  pre {
    background-color: #f5f7fa !important;
    font-size: 16px !important;
    font-family: "Google Sans";
    padding: 10px !important;
    border-radius: 6px !important;
    margin-bottom: 16px !important;
    line-height: 24px;
    white-space: pre-line;
    margin-top: 0px !important;
    color: #2c3e50 !important;
  }

  blockquote {
    border-left: 4px solid #10486b !important;
    margin-bottom: 16px !important;
    margin-top: 0px !important;
    padding-left: 16px;
    font-size: 16px;
    font-family: "Google Sans";
    color: #2c3e50;
    line-height: 24px;
    list-style-type: inherit;
    margin-left: 0px;
    font-style: italic;
  }
  .ql-snow .ql-editor img {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
    margin: auto;
    display: block;
    max-width: 100%;
    height: auto;
  }
  .ql-editor li:not(.ql-direction-rtl)::before {
    margin-right: 11px;
  }
  .ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 32px;
  }
  .ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 48px;
  }
  .ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 84px;
  }
  .ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 120px;
  }
  .ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 157px;
  }
  .ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 192px;
  }
  .ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 230px;
  }
  .ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 266px;
  }
  .ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 303px;
  }
  li {
    position: relative;
    font-size: 16px;
    font-family: "Google Sans";
    color: #2c3e50;
    line-height: 24px;
    list-style-type: inherit;
    margin-bottom: 12px;
    padding-left: 12px;
  }
  li::before {
    white-space: nowrap;
    width: 24px;
    line-height: normal;
    left: 0px;
    text-align: center;
    vertical-align: baseline;
    text-align: center;
    position: relative;
  }
  ul {
    padding-left: 0px;
    margin: 0px 0px 4px 0px;
    list-style-type: none;
    li {
      margin-left: 16px;
    }
    li::before {
      font-size: 24px;
      top: 2px;
    }
  }

  .ql-editor ul li:not(.ql-direction-rtl) {
    padding-left: 4px;
  }
  ol {
    padding-left: 0px;
    margin: 0px 0px 4px 0px;
    &:first-of-type {
      counter-reset: list-0;
    }
    li {
      text-indent: -10px;
    }
  }
  .ql-editor ol li:not(.ql-direction-rtl) {
    padding-left: 26px;
  }
  p + ol {
    counter-reset: list-0;
  }

  h1 + ol {
    counter-reset: list-0;
  }

  h2 + ol {
    counter-reset: list-0;
  }

  h3 + ol {
    counter-reset: list-0;
  }
  blockquote + ol {
    counter-reset: list-0;
  }
  pre + ol {
    counter-reset: list-0;
  }

  .ql-container {
    border: none !important;
  }

  .ql-editor {
    padding: 0px;
    min-height: 100px;
  }

  .ql-editing {
    left: 0px !important;
  }

  .ql-toolbar {
    width: fit-content;
    display: flex;
    padding: 4px !important;
    border: none !important;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 2px 12.9px 0px rgba(35, 40, 105, 0.09);
    button:hover {
      background: #f5f7fa;
      border-radius: 6px;
      color: #667085;
      .ql-stroke {
        stroke: #667085;
      }
      .ql-fill {
        fill: #667085;
      }
      svg path {
        fill: #667085;
      }
    }
    .ql-bold {
      padding: 0px;
      width: auto;
      height: auto;
    }

    .ql-italic {
      padding: 0px;
      width: auto;
      height: auto;
    }
    .ql-underline {
      padding: 0px;
      width: auto;
      height: auto;
    }
    .ql-formats {
      display: flex;
      align-items: center;
      gap: 6px;
      margin-right: 0px;
      border-right: 1px solid #d0d5dd;
    }
    .ql-header {
      padding: 0px;
      .ql-stroke {
        stroke: #b3b7c2;
      }
      .ql-fill {
        fill: #b3b7c2;
      }
      // width: auto;
      // height: auto;
    }
    .ql-align {
      padding: 0px;
      width: auto;
      height: auto;
    }
    .ql-align-center {
      padding: 0px;
      width: auto;
      height: auto;
    }
    .ql-align-right {
      padding: 0px;
      width: auto;
      height: auto;
    }
    .custom-header {
      padding-left: 6px;
    }
    .custom-align {
      padding-left: 6px;
    }
    .ql-blockquote {
      padding: 0px;
      width: auto;
      height: auto;
    }
    .ql-code-block {
      padding: 0px;
      width: auto;
      height: auto;
    }
    .custom-code {
      padding-left: 6px;
    }
    .ql-list {
      padding: 0px;
      width: 24px;
      height: 24px;
      .ql-stroke {
        stroke: #b3b7c2;
      }
      .ql-fill {
        fill: #b3b7c2;
      }
    }
    .ql-indent {
      padding: 0px;
      width: 24px;
      height: 24px;
      .ql-stroke {
        stroke: #b3b7c2;
      }
      .ql-fill {
        fill: #b3b7c2;
      }
    }
    .custom-list {
      padding-left: 6px;
    }
    .ql-picker-label {
      border: none;
      padding: 0px 2px;
      .ql-stroke {
        stroke: #b3b7c2;
      }
      .ql-fill {
        fill: #b3b7c2;
      }
    }
    .ql-picker-label:hover {
      .ql-stroke {
        stroke: #667085;
      }
      .ql-fill {
        fill: #667085;
      }
    }
    .custom-color {
      padding-left: 6px;
    }
    .ql-link {
      padding: 0px;
      width: auto;
      height: auto;
    }
    .ql-image {
      padding: 0px;
      width: 24px;
      height: 24px;
      .ql-stroke {
        stroke: #b3b7c2;
      }
      .ql-fill {
        fill: #b3b7c2;
      }
    }
    .ql-doc {
      padding: 0px;
      width: auto;
      height: auto;
      cursor: pointer;
    }
    .custom-link {
      border-right: none;
      padding-left: 6px;
    }
    .ql-active {
      background: #f5f7fa;
      border-radius: 6px;
      color: #667085 !important;
      .ql-stroke {
        stroke: #667085 !important;
      }
      .ql-fill {
        fill: #667085 !important;
      }
      svg path {
        fill: #667085 !important;
      }
    }
  }
}
.rich-text-disable-toolbar {
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .ql-container {
    border: none !important;
  }
  .ql-toolbar {
    width: fit-content;
    display: none;
    padding: 4px !important;
    border: none !important;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 2px 12.9px 0px rgba(35, 40, 105, 0.09);
    .after::after {
      content: "|";
      color: #d0d5dd;
      font-size: 25px;
      margin-right: 6px;
    }
    .ql-formats {
      height: 24px;
      margin-right: 0px !important;
      display: flex;
      align-items: center;
      gap: 6px;

      .ql-picker {
        height: fit-content;
        width: fit-content;
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
      }
    }
  }
}
.delete-check {
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: 10px;
  right: 16px;
  img {
    cursor: pointer;
  }
  svg {
    cursor: pointer;
  }
}
.file-upload {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  // border: 1px dashed var(--Border-color, #d0d5dd);
  background: var(--Shades-White, #fff);
  .upload-container {
    padding: 40px;
    label {
      border: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      p {
        color: var(--Text2, #667085);
        text-align: center;
        font-family: "Google Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        letter-spacing: -0.084px;
      }
    }
    .upload-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      min-width: 400px;
    }
  }
}
.add-row {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 1px dashed var(--Border-color, #d0d5dd);
  background: var(--Shades-White, #fff);
  .add-row-container {
    padding: 40px;
    button {
      border: none;
      p {
        color: var(--primary-blue, #0091ea);
        text-align: center;
        font-family: "Google Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }
}
.embed-code {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 1px dashed var(--Border-color, #d0d5dd);
  background: var(--Shades-White, #fff);
  .embed-code-container {
    padding: 40px;
    width: 100%;
    .ql-toolbar {
      display: none;
    }
    .ql-container {
      width: 100%;
      border: none;
      .ql-editor {
        color: var(--Text2, #667085);
        // text-align: center;
        font-family: "Google Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        letter-spacing: -0.084px;
      }
      .ql-editor::before {
        text-align: center;
      }
    }
  }
}
.quote-upload {
  width: 100%;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 1px dashed var(--Border-color, #d0d5dd);
  background: var(--Shades-White, #fff);
  .ql-editor {
    margin-left: 63px;
    color: var(--Text, #162f3f);
    font-family: "Google Sans";
    font-size: 24px;
    font-style: italic;
    font-weight: 400;
    line-height: 36px; /* 150% */
    letter-spacing: -0.144px;
  }
  .ql-editor::before {
    margin-left: 52px;
  }
  .quote-upload-container {
    width: 100%;
    img {
      position: absolute;
      top: 44px;
      left: 40px;
    }
  }
}

.view-editor {
  p {
    font-size: 16px;
    font-family: "Google Sans";
    color: #2c3e50;
    line-height: 24px;
  }
  pre {
    background-color: #f5f7fa;
    font-size: 16px;
    font-family: "Google Sans";
    padding: 5px 10px;
    border-radius: 6px;
    margin-top: 5px;
    margin-bottom: 5px;
    line-height: 24px;
    white-space: pre-line;
  }
  blockquote {
    border-left: 4px solid #1677ff;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
    font-size: 16px;
    font-family: "Google Sans";
    color: #2c3e50;
    line-height: 24px;
    list-style-type: inherit;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  li {
    font-size: 16px;
    font-family: "Google Sans";
    color: #2c3e50;
    line-height: 24px;
    list-style-type: inherit;
    padding-left: 0px;
  }
  .ql-align-center {
    text-align: center;
  }
  .ql-align-right {
    text-align: right;
  }
  ol {
    padding-left: 20px;
    margin: 0px;
  }
  ul {
    padding-left: 14px;
    margin: 0px;
  }
  img {
    max-width: 100%;
  }
}

.research-view-header {
  h1,
  p {
    font-weight: 700;
    line-height: 42px;
    font-size: 28px;
    color: #162f3f;
  }
}

.research-name {
  padding: 10px;
  h1,
  p {
    font-weight: 500;
    line-height: 24px;
    font-size: 14px;
    color: #2c3e50;
  }
}

.read-quill {
  .ql-toolbar {
    display: none;
  }
  border: none !important;
  padding: 0px 0px 0px 0px !important;

  p,
  h1,
  h2,
  h3,
  ol,
  ul,
  pre,
  blockquote {
    cursor: default;
  }
}
