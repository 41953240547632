.home {
  h3 {
    color: var(--Text, #162f3f);
    font-family: "Google Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 140% */
    letter-spacing: -0.2px;
  }
  .research-list {
    span {
      color: var(--Text2, #667085);
      font-family: "Google Sans";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
    }
    .research-info {
      p {
        margin-bottom: 10px;
      }
      strong {
        font-weight: 400;
      }
    }
    .research-date {
      display: flex;
      gap: 8px;
      align-items: center;
    }
    .researchs {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    .title {
      margin-bottom: 20px;
    }
  }
}

.filter-tag {
  padding: 4px 8px !important;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.084px;
  border-radius: 4px;
  padding: 4px 8px;
  color: var(--Text2, #667085);
  background-color: #ffffff;
  border: 1px solid #f9f9f9;
  .ant-tag-close-icon {
    margin-left: 8px;
  }
}
.filter-tags-container {
  display: flex;
  gap: 16px;
  width: 100%;
  flex-wrap: wrap;
}

.clear-all-btn {
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
  border: 1px solid #e53935;
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
  height: 46px;
  p {
    color: var(--Error, #e53935);
    text-align: right;
    font-family: "Google Sans";
    font-size: 16px;
    font-weight: 400;
  }
}

.home-company {
  .feed-drawer .layout-body {
    height: calc(100vh - 276px) !important;
  }
}
