.feed-row {
  cursor: pointer;
}
.feed-drawer {
  transition: all 0.3s;
  overflow: auto;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.0509803922);
  background-color: #fff;
  position: relative;
  .header {
    height: 51px;
    display: flex;
    align-items: center;
    padding: 0px 16px;
    justify-content: space-between;
    h1 {
      font-size: 26px;
      font-weight: 600;
    }
  }
  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 28px;
    font-weight: 700;
    color: #0081d0;
  }
  .layout-container {
    height: auto !important;
  }
  .layout-body {
    height: calc(100vh - 237px) !important;
    width: calc(100% - 16px) !important;
    padding-left: 16px;
  }
  .layout-header {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .icon {
    width: 30px;
    height: 30px;
    min-width: 30px;
    margin-left: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 10px;
  }
  .icon:hover {
    background-color: #f3f5f6;
  }
  .skeleton {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.ant-drawer-inline{
  top: 0%;
  margin-top: -60px !important;
  margin-left: -50px !important;
}

.ant-drawer .ant-drawer-content-wrapper{
  height: unset !important;
}

.custom-dropdown-overlay {
  width: 140px;
}

.even-row > td {
  background: #F2F9FE !important; /* Change this to your desired color */
}

.custom-table {
  border-radius: 10px;
  overflow: auto;
  width: 100%;
  background-color: #ffffff !important;
  .ant-table-wrapper table{
    background: white !important;
  }
  .ant-table-wrapper .ant-table-thead > tr > th {
    padding-left: 20px !important;
    padding-right: 20px !important;
    background: #B3B7C266;
    color: #162f3f;
    font-size: 14px;
    font-weight: 700;
    height: 52px;
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    color: #667085;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
  .ant-pagination {
    padding-right: 16px;
  }

  tr {
    cursor: pointer;
  }
  // .ant-table-wrapper td.ant-table-column-sort {
  //   background: transparent;
  // }
  .ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: #a1d4f7 !important;
  }
  .ant-table-wrapper td.ant-table-column-sort {
    background: transparent; /* Change this to your desired color */
  }

  td {
    height: 60px;
  }
  thead {
    td {
      height: 50px !important;
      background: #dee2e7 !important;
    }
  }
}

.company-column {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 14px;
  color: #0091ea;
  text-decoration: underline;
}
.selected-row {
  background: #65b8f1 !important;
}
