.upload-research {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .upload-top {
    display: flex;
    justify-content: space-between;
    .upload-title {
      display: flex;
      align-items: center;
      gap: 15px;
      img {
        cursor: pointer;
      }
      h5 {
        color: var(--Text, #162f3f);
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 140% */
        letter-spacing: -0.2px;
      }
    }
    .upload-btn {
      display: flex;
      align-items: center;
      gap: 16px;
      .save-draft {
        cursor: pointer;
        border: none;
        background: none;
        color: var(--primary-blue, #0091ea);
        font-family: "Google Sans";
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
      .save-research {
        cursor: pointer;
        padding: 12px 36px;
        border-radius: 30px;
        background: var(--primary-blue, #0091ea);
        border: none;
        color: #fff;
        font-family: "Google Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
      .cancel {
        cursor: pointer;
        padding: 12px 36px;
        border-radius: 30px;
        border: 1px solid var(--Border-color, #d0d5dd);
        background: none;
        color: var(--Text, #162f3f);
        font-family: "Google Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }
  .upload-container {
    border-radius: 20px;
    background: #fff;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 26px;
    .quill {
      height: calc(100vh - 380px);
      overflow: auto;
    }
    .rich-text {
      padding-right: 0px;
    }
    .ql-editor {
      padding-right: 16px;
    }
  }
}

@media screen and (max-width: 768px) {
  .upload-research {
    .upload-container {
      padding: 30px 20px;
    }
  }
}
