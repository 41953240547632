.search-global-popover {
  .ant-popover-inner {
    padding-right: 0px;
  }
  .item {
    display: flex;
    margin-right: 12px;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
    cursor: pointer;
  }
  .item:hover {
    background-color: #f9f9f9;
  }
}
