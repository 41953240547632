.header-wrapper {
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100vw;
  background: white;
  .header-container {
    position: relative;
    margin: 0 auto;
    display: flex;
    z-index: 10;
    justify-content: space-between;
    align-items: center;
    padding: 0px 40px;
    .header-img {
      cursor: pointer;
      .logo-icon {
        width: 234px;
      }
    }
    .animation-ellipse {
      position: absolute;
      width: 286.371px;
      height: 286.332px;
      transform: rotate(28.93deg);
      flex-shrink: 0;
      filter: blur(150px);
    }
  }
}
.btn-menu-mobile {
  display: none;
  .btn-menu {
    position: relative;
    display: flex;
    gap: 10px;
    .menu-hamburger {
      width: 32px;
    }
    .logo-icon {
      width: 34px;
    }
  }
}
/* drawer */
.ant-drawer {
  // z-index: 3 !important;
  top: 60px;
  .ant-drawer-mask {
    width: 100%;
    background-color: unset;
  }
  .ant-drawer-content-wrapper {
    width: 100%;
    height: 100vh !important;
  }
  .ant-drawer-content {
    background: unset !important;
    width: 100%;
    .ant-drawer-body {
      width: 100%;
    }
  }
}
.ant-drawer .ant-drawer-mask {
  background: rgba(0, 0, 0, 0.9);
}

.navigation {
  // width: 0%;
  display: flex;
  justify-content: center;
  .ant-menu-overflow-item {
    text-align: center;
  }

  .navigtion-container {
    display: flex;
    align-items: center;
    .menu-active {
      color: #0091ea;
    }

    p {
      color: var(--Text, #162f3f);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      max-width: 180px;
      margin: 28px;
      cursor: pointer;
      &:hover {
        color: #0091ea;
      }
    }
  }
}
.nav-right {
  display: flex;
  align-items: center;
  gap: 20px;
  .login {
    color: #0091ea;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-decoration: none;
  }
  .btn-join-now {
    border-radius: 20px;
    background: #0091ea;
    padding: 8px 25px;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;
  }
  .btn-join-now:hover {
    color: #fff;
  }
}
.sub-menu-text {
  color: var(--Text, #162f3f);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 100px;
}

@media only screen and (max-width: 900px) {
  .btn-menu-mobile {
    display: unset;
  }
  .menu-pc {
    display: none;
  }
  .header-img {
    display: none !important;
  }
  .header-container {
    padding: 10px 20px !important;
  }
}
