.footer-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 29px;
  padding: 40px 0px;

  .footer-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    .footer-logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 14.286px;
    }
    .footer-menu {
      text-align: center;
      a {
        cursor: pointer;
        color: #162f3f;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        &:hover {
          color: #0091ea;
        }
      }
      &__items {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 40px;
      }
      &__subItems {
        margin-top: 20px;
        display: inline-flex;
        justify-content: center;
        gap: 20px;
        a {
          color: #667085;
          font-size: 16px;
        }
      }
    }
    .footer-social {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      img {
        cursor: pointer;
      }
    }
  }
}
.footer-coppyright {
  p {
    color: #667085;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.084px;
  }
}
@media only screen and (max-width: 430px) {
  .footer-wrapper {
    margin-top: 40px;
    .footer-container {
      gap: 30px;
    }
  }
}
