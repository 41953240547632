.insights {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  &-banner__cards {
    // max-width: 1440px;
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    margin-bottom: 60px;
    :global(.custom-card:nth-child(odd)) {
      margin-top: 60px;
    }
    :global(.custom-card:nth-child(even)) {
      margin-bottom: 60px;
    }
  }
  &-why-choose {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .insights {
    &-banner__cards {
      :global(.custom-card) {
        width: 100%;
        max-width: 400px;
        margin: 0px !important;
      }
    }
  }
}
