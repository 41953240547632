.menu-settings {
  min-width: 320px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: #fff;
  height: fit-content;
  .account-setting {
    padding: 30px 0px 30px 30px;
    display: flex;
    align-items: center;
    gap: 22px;
    border-bottom: 1px solid var(--Grey-2, #f5f5f5);
    .account-name {
      h5 {
        color: var(--Text, #162f3f);
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 140% */
        letter-spacing: -0.2px;
      }
      p {
        width: fit-content;
        color: var(--primary-vibrant, #00bfa5);
        text-align: right;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        padding: 5px 16px;
        border-radius: 5px;
        background: rgba(0, 191, 165, 0.08);
      }
    }
    .avatar {
      position: relative;
      .update-icon {
        position: absolute;
        cursor: pointer;
        right: -2px;
        bottom: -4px;
      }
      img {
        width: 80px;
        height: 80px;
        border-radius: 99999px;
      }
    }
  }
  .menu-bottom {
    padding: 30px 15px 366px 15px;
  }
}
.menu-content {
  display: none;
}
.setting-content {
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  border-radius: 10px;
  background: var(--White, #fff);
  h5 {
    color: var(--Text, #162f3f);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 140% */
    letter-spacing: -0.2px;
  }
  .setting-item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 30px;
    padding-top: 30px;
    border-bottom: 1px solid var(--Grey-2, #f5f5f5);
    .checkbox-group {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .checkbox-container {
      display: flex;
      gap: 15px;
      .checkbox-text {
        display: flex;
        flex-direction: column;
        gap: 10px;
        p {
          color: var(--Text, #162f3f);
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
        span {
          color: var(--Text2, #667085);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
    .sub-checkbox {
      color: var(--Text2, #667085);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 10px;
    }
  }
}
.btn-upload-setting {
  display: flex;
  justify-content: flex-end;
  button {
    padding: 12px 36px;
    border: none;
    border-radius: 30px;
    background: var(--primary-blue, #0091ea);
    color: var(--White, #fff);
    font-family: "Google Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;
  }
}
@media only screen and (max-width: 430px) {
  .fz16-14 {
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 22px !important; /* 157.143% */
    letter-spacing: -0.084px !important;
  }
  .btn-upload-setting {
    justify-content: center;
  }
  .menu-settings {
    width: unset;
    .account-setting {
      width: 100%;
      padding: 0px;
      border-radius: 10px;
      background: #fff;
      img {
        padding: 15px 0px 15px 15px;
      }
    }
    .menu-bottom {
      display: none;
    }
  }
  .setting-content {
    width: unset;
    padding: 15px;
    gap: 20px;
    border-radius: 0px 0px 10px 10px;
    margin-bottom: 40px;
    .setting-item {
      padding-bottom: 20px;
      .checkbox-group {
        gap: 10px;
      }
      .checkbox-container {
        .checkbox-text {
          span {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
            letter-spacing: -0.084px;
          }
        }
      }
    }
  }
  .menu-content {
    display: unset;
    margin-bottom: -9px;
    background: #fff;
    border-radius: 10px 10px 0px 0px !important;
    .ant-menu {
      padding: 10px 0px;
      border-radius: 10px 10px 0px 0px !important;
      width: 100%;
      overflow-x: scroll;
      flex-direction: row;
      gap: 4px;
      .ant-menu-item-selected {
        .ant-menu-title-content {
          color: #162f3f !important;
          text-align: center;
          font-family: "Google Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 500 !important;
          line-height: 22px; /* 157.143% */
          letter-spacing: -0.084px;
        }
      }
      .ant-menu-item {
        overflow: unset;
        padding: 5px 10px !important;
        .ant-menu-title-content {
          color: var(--Text2, #667085);
          text-align: center;
          font-family: "Google Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
          letter-spacing: -0.084px;
        }
      }
    }
  }
}
