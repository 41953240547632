.auth-container {
  overflow-y: scroll;
  display: flex;

  .auth-left {
    background: linear-gradient(
        347deg,
        rgba(242, 249, 254, 0) 17.97%,
        #f2f9fe 62.3%
      ),
      url(../../assets/images/background_auth.png);
    // filter: blur(2px);
    width: 50%;
    max-width: 720px;
    background-repeat: no-repeat;
    background-position: bottom;
    height: 100vh;
  }
  .auth-right {
    width: 50%;
    min-width: calc(100% - 720px);
    height: 100vh;
    background-color: #f2f9fe;
    display: flex;
    justify-content: center;
    padding: 2px 50px;
  }
}
.auth-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .auth-content-top {
    padding: 140px 62px 0px 80px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    h2 {
      color: #0091ea;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 54px; /* 133.333% */
    }
    p {
      color: rgb(102, 112, 133);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .auth-content-bottom {
    padding: 0px 168px 95px 80px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    p {
      color: var(--base-white, #fff);
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      line-height: 34px;
    }
    .co-founder {
      display: flex;
      align-items: center;
      gap: 12px;
      h5 {
        color: var(--base-white, #fff);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 175% */
        letter-spacing: -0.2px;
      }
      p {
        color: var(--Grey-1, #e0e0e0);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        letter-spacing: -0.084px;
      }
    }
  }
}
.auth-form {
  height: 100%;
  display: flex;
  align-items: center;
  min-width: 512px;
  max-width: 512px;
  .form-content {
    min-height: calc(100vh - 390px);
    .form-logo {
      cursor: pointer;
      width: 238px;
    }

    width: 100%;
    // padding: 0px 50px;
    display: flex;
    flex-direction: column;
    gap: 48px;
    .form {
      display: flex;
      flex-direction: column;
      gap: 30px;
      h3 {
        color: var(--Text, #162f3f);
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px; /* 171.429% */
        letter-spacing: -0.56px;
      }
      h4 {
        color: var(--Text, #162f3f);
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px; /* 200% */
        letter-spacing: -0.48px;
      }
      h2 {
        color: var(--Text, #162f3f);
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px;
        letter-spacing: -0.72px;
      }
      .form-item {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
      .btn-item {
        display: flex;
        flex-direction: column;
        gap: 32px;
      }
    }
    .btn-container {
      display: flex;
      flex-direction: column;
      gap: 48px;
    }
    .btn-get-started {
      cursor: pointer;
      width: 100%;
      border: none;
      padding: 12px 36px;
      border-radius: 30px;
      background: var(--primary-blue, #0091ea);
      color: #fff;
      font-family: "Google Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
    .btn-disabled {
      background: #00000008 !important;
      color: #00000040 !important;
    }

    .remember-fogot {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        cursor: pointer;
        color: var(--Text, #162f3f);
        text-align: right;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }
    .text-bottom {
      p {
        color: var(--Text2, #667085);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        span {
          cursor: pointer;
          color: var(--primary-blue, #0091ea);
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }
  }
}
.auth-content-mobile {
  display: none;
  background: url(../../assets/images/background-auth-mobile.png);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  .auth-content-mobile-item {
    padding: 20px;
    height: 220px;
    display: flex;
    align-items: flex-end;
    p {
      height: fit-content;
      color: #0091ea;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}
.react-multi-carousel-dot-list {
  gap: 10px;
  justify-content: flex-start !important;
  top: 50%;
  left: 20px !important;
}
.active {
  svg {
    fill: #fff;
  }
}
@media only screen and (max-width: 430px) {
  .auth-content {
    display: none;
  }
  .auth-content-mobile {
    display: block !important;
  }
  .auth-form {
    .form-content {
      padding: 30px 20px;
      gap: 20px;
      justify-content: space-between;
      .btn-container {
        gap: 15px;
      }
      .form {
        gap: 15px;
        .form-item {
          gap: 15px;
        }
      }
      .form-logo {
        display: none;
      }
      h3 {
        font-size: 20px;
        text-align: center;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 140% */
        letter-spacing: -0.2px;
      }
      h4 {
        text-align: center;
      }
      h2 {
        text-align: center;
      }

      .text-bottom {
        p {
          text-align: center;
        }
      }
    }
  }
}

// @media only screen and (min-width: 600px) and (max-width: 900px) {
//   .auth-content {
//     display: none;
//   }
//   .auth-content-mobile {
//     display: block !important;
//     height: 100%;
//   }
//   .auth-form {
//     .form-content {
//       .form-logo {
//         display: none;
//       }
//       padding: 0px 50px;
//     }
//   }
// }
@media only screen and (max-width: 1024px) {
  .auth-form {
    min-width: auto;
    width: 100%;
  }
  .auth-content .auth-content-top {
    padding: 140px 62px 0px 50px;
  }
}

@media only screen and (max-width: 430px) {
  .auth-container {
    overflow-y: unset;
    flex-direction: column;
    height: unset;
    .auth-left {
      width: unset;
      background: unset;
      height: unset;
      margin-top: 70px;
    }
    .auth-right {
      width: unset;
      height: unset;
    }
    .auth-form {
      padding: 0px;
    }
  }
}
