.menu-drawer {
  .header {
    display: flex;
    justify-content: space-between;
    svg {
      width: 32px;
      height: 32px;
    }
    .close-btn {
      cursor: pointer;
    }
  }

  .body {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 44px;
    .item-drawer {
      color: white;
      margin-bottom: 34px;
      font-size: 20px;
      transition: all linear 0.3s;
      :global(.nav-link) {
        color: #162f3f;
        span {
          text-align: center;
          font-size: 28px;
          font-style: normal;
          font-weight: 400;
          line-height: 40px;
        }
      }
      :global(.active) {
        color: #0091ea;
      }
      &:hover {
        color: #0091ea;
      }
    }
  }
}
