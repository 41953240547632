.container {
  height: 100vh;
  display: flex;
}
.header-auth {
  width: 154.703px;
  padding: 15px 0px;
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;
}
.cms {
  flex: 1;
  height: 100%;
  overflow: hidden;
}

@media only screen and (max-width: 430px) {
  .header-auth {
    display: flex;
    position: fixed;
    top: 0;
    background: #fff;
    z-index: 10;
  }
  .children {
    // margin-top: 70px;
    // min-height: calc(100vh - 70px);
  }
  .cms {
    margin-top: 60px;
    padding: 30px 20px;
    .cms-container {
      margin-left: 0;
      margin-top: 0;
    }
  }
}
@media only screen and (min-width: 430px) and (max-width: 1024px) {
  .cms {
    .cms-container {
      margin-left: 0;
      margin-top: 0;
    }
  }
}

.layout-mobile {
  overflow: auto;
  .header {
    width: calc(100% - 40px);
    height: 70px;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    position: fixed;
    background: #f3f5f6;
    z-index: 1000;
  }
  .logo {
    width: 34px;
    height: 34px;
    cursor: pointer;
    margin-left: 10px;
  }
  .expand {
    cursor: pointer;
  }
}

.drawer-mobile {
  .ant-drawer-header {
    padding: 12px 20px;
    border-bottom: none;
    .root-avatar {
      display: flex;
      align-items: center;
      .fullName {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        color: #162f3f;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .email {
        margin-top: 4px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        color: #667085;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.root-drawer-mobile {
  top: 70px !important;
  outline: none;
  .ant-drawer-content-wrapper {
    box-shadow: none;
  }
  .ant-drawer-body {
    padding: 8px 0px;
  }
  .ant-drawer-close {
    display: none;
  }
}
